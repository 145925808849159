import React from "react";
import ReactPlayer from "react-player";
import video from "./video.webm";

function App() {
  return (
    <>
      <div className="hero-section-video-container">
        <ReactPlayer
          url={video}
          autoplay={true}
          playsinline={true}
          playing={true}
          controls={false}
          loop={true}
          muted={true}
          width="100%"
          height="100%"
          style={{ objectFit: "cover" }}
          className="hero-section-video-player"
        />
      </div>
    </>
  );
}

export default App;
